import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class TaxPayableService{
    getTaxPayableLists(param){
        const url = `api/tax/payable/filter`
        const params = {
            'page-index': param.size,
            'from':param.from,
            'to':param.to,
            'searched':param.searched,
            'offset':param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getTaxPayableElements(){
        const url = `api/tax/payable/elements`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getTaxReceiptNo(){
        const url = `api/tax/payable/receipt/no`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createTaxPayable(formdata){
        const url = `api/tax/payable/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    editTaxPayable(id,formdata){
        const url = `api/tax/payable/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    cancelTaxPayable(id){
        const url = `api/tax/payable/cancel/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
}
export default new TaxPayableService();